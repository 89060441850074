@import "constants";

.default-table-container {
  width: 100%;
  overflow-x: auto;
}
.default-table {
  width: 100%;
  min-width: 800px;
  b {
    font-weight: 400 !important;
  }

  tr {
    th {
      padding: 10px;
      color: $font-color;
      letter-spacing: 0px;
    }
    th:first-child,
    td:first-child {
      padding-left: 50px !important;
    }
    th:last-child,
    td:last-child {
      padding-right: 50px !important;
    }
  }
  thead {
    background-color: $lighter-gray;

    th {
      font-weight: 400;
      font-size: 15px;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $light-gray;
    }
    td {
      letter-spacing: 0px;
      padding: 30px 10px;
      font-weight: 300;
      color: $font-color;
      font-size: 15px;
      a {
        font-weight: 400;
        color: whi !important;
        &:hover {
          color: $parent-line-dark-color !important;
        }
        text-decoration: none !important;
      }
    }
  }
}

.full-screen-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
