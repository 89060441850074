/* * {
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
  Garamond,serif,
  "Helvetica Neue", Arial, sans-serif;
  } */

  input::placeholder {
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
      "Helvetica Neue", Arial, sans-serif !important;
  }
  
  body{
    --primary-color: #7daafb;
    margin: 0;
    background-color: #F5F5F5;
  }

  .card-style {
    width: 25rem !important;
    border-radius: 5px !important;
    border-width: 1px !important;
    border-color: #F3F3F3 !important;
    opacity: 1px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    cursor: pointer !important;
  }
  
  


  
