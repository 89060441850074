@import "constants";

@media screen and (max-width: 700px) {
  .content {
    margin-left: 0 !important;
  }
  .side-navigation {
    .side-toggler {
      display: inline-block !important;
      position: absolute;
      right: 8px;
      top: 8px;
      padding: 0 5px;
      color: $font-color;
    }
  }
}

@media screen and (max-width: 769px){
  .course-taken-card {
    padding: 1rem 1rem !important;
  }
}
