$ontraq-color: #5e679d;
$ontraq-dark-color: #29357c;

$parent-line-color: #badc8a;
$parent-line-dark-color: #a3d063;

$background-color: #f5f5f5;
$white: #fff;
$black: #000;
$gray: #bcbcbc;
$light-gray: #dddddd;
$lighter-gray: #fafafa;
$font-color: #707070;
$drop-shadow: 0px 3px 6px #00000029; ;
