@import "constants";
@import "dashboard";
@import "mobile_responsive";
@import "components";

* {
  font-family: "OpenSans", "Arial" ,"sans-serif"}

body,
html {
  margin: 0 !important;
  padding: 0 !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label {
  color: $font-color;
}

a {
  &.parentline-link {
    color: $parent-line-color !important;
  }

  &.ontraq-link {
    color: $ontraq-color !important;
  }
}

.form-label {
  font-size: 15px !important;
  margin-bottom: 10px !important;
}

/*
.form-control {
  border: 1px solid $parent-line-dark-color !important;
  font-size: 15px !important;
  &:focus {
    outline: 0 none;
    box-shadow: 0 0px 8px $parent-line-dark-color !important;
  }
  &.ontraq-color {
    border: 1px solid $ontraq-dark-color !important;
  }
  &.ontraq-color:focus {
    outline: 0 none;
    box-shadow: 0 0px 8px $ontraq-color !important;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ 
    color: #ddd !important;
    opacity: 1; /* Firefox 
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 
    color: #ddd !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge 
    color: #ddd !important;
  }
} */

.parent-line-font {
  color: $parent-line-dark-color;
}

.btn {
  font-size: 15px !important;
  padding: 15px auto !important;
  border-radius: 5px !important;

  &.btn-parentline {
    background-color: $parent-line-dark-color !important;
    border-color: $parent-line-color;

    &:focus {
      outline: 0 none;
      box-shadow: 0 0px 8px $parent-line-color !important;
    }
  }

  &.btn-ontraq {
    background-color: $ontraq-dark-color !important;
    border-color: $ontraq-color;

    &:focus {
      outline: 0 none;
      box-shadow: 0 0px 8px $ontraq-color !important;
    }
  }
}

.text-right {
  text-align: right !important;
  margin-bottom: 10px;

}

.cursor-pointer {
  cursor: pointer
}

.text-parentline {
  color: $parent-line-dark-color;
}


.video-holder {
  display: flex;
  justify-content: center !important;
  // height: 300px;
}

.text-ontraq {
  color: $ontraq-dark-color;
}

.bg-ontraq {
  background-color: $ontraq-color !important;
}

.bg-parentline {
  background-color: $parent-line-color;
}

.shadow {
  box-shadow: $drop-shadow !important;
}

// .lgn-page {
/*~~~~~~~~~~~~~LandingPage scss~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// .landingPage-bg-image {
//   background-image: url("../images/landingPagebg1.png");
//   background-size: cover;
//   background-color: #cccccc;
//   background-repeat: no-repeat;
//   height: 100vh;
// }

.landingPage-font-h1 {
  color: #3E3D3D !important;
  font-size: 52px !important;
}

.landingPage-font-p {
  color: #3E3D3D !important;
  font-size: 32px !important;
}

.landingPage-btn {
  min-width: 420px !important;
  height: 77px !important;
  border-radius: 39px !important;
  font-size: 32px !important;
}

.span-color {
  color: red;
  font-size: 100px;
}

.padding {
  padding-top: 400px;
}

.landing-text-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

// }

/*~~~~~~~~~~~~~LandingPage School Logo scss~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.schoolLogo-container {
  &.landingPage-logo {
    max-height: 190px !important;
    max-width: 190px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .logo-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    margin: 50px 0px !important;
    column-gap: 30px;
    row-gap: 30px;
  }

  .schoolLogo-holder {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  &.logos-padding {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &.side-padding {
    padding-top: 125px;
  }
}

.landingPage-course {
  .card {
    --bs-card-border-radius: 1.375rem;
  }

  background-color: #F3F6FF;

  &.main-padding {
    padding: 45px
  }

  &.h1-padding {
    padding: 25px;
  }

  &.digital-marketing {
    color: #2cc4b6 !important;
    background-color: white;
  }

  &.ui-ux-design {
    color: #1A62C3 !important;
    background-color: white;
  }

  &.marketing-analytics {
    color: #A501FF !important;
    background-color: white;
  }

  &.font-size {
    font-size: 13px !important;
    color: black;
    background-color: white;
  }
}

.card {
  --bs-card-border-radius: 1.375rem;
}


.landingPage-footer {
  &.landingPage-footer-bg {
    background-color: #1C71DE;
    color: #cccccc;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &.footer-h1 {
    font-size: 48px;
    color: white;
  }

  &.cosmos-dot {
    color: red;
    font-size: 50px;
  }

  &.border-top {
    border-top: 20px solid rgb(255, 0, 0);
    font-display: center;
  }

  &.p-24 {
    font-size: 24px;
    color: white;
  }

  &.p-18 {
    font-size: 18px;
    color: white;
  }

  &.p-28 {
    color: white;
    font-size: 28px;
  }

  &.img-padding {
    padding-right: 18px;
  }

  &.p-20 {
    color: white;
    font-size: 20px;
  }

  &.textAlign {
    text-align: center;
  }

  &.paddingTop-20 {
    color: white;
    padding-top: 20px;
  }

  .landingPage-base1 {
    padding-left: 0px;
    margin-left: -7px;
  }

  .landingPage-base2 {
    padding-right: 0px;
    margin-right: -375px;
  }

  .form-control {
    background: #2F88CA;
    border-radius: 9px;
    border: transparent;
    height: 37px;
    color: #FFFFFF;
    opacity: 1;
    max-width: 365px;
  }

  .input-group-text {
    background: #2F88CA;
    border: transparent;
    color: #FFFFFF;
    opacity: 1;
  }

  input::placeholder {
    color: #FFFFFF;
  }

}

body{
  .indicator{
    position:relative;
    width:50px;
    height:50px;
    transform:rotate(45deg);
    span{
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      box-sizing:border-box;
      border:none;
      border-bottom:3px solid #fff;
      border-right:3px solid #fff;
      animation:animate 1s linear infinite;
      &:nth-child(1){
        top:-30px;
        left:-30px;
        animation-delay:0s;
      }
      &:nth-child(2){
        top:-15px;
        left:-15px;
        animation-delay:0.3s;
      }
    }
  }
}
@keyframes animate{
  0%{
    border-color:#1A62C3;
    transform:translate(0,0);
  }
   20%{
    border-color:#1A62C3;
     transform:translate(15px,15px);
  }
   20.1%,100%{
    border-color:#fff;
  }
}


.login {
  .left-side {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #1C71DE !important;
    height: 100vh !important;

    .container {
      display: flex !important;
      justify-content: center !important;
      flex-direction: column !important;
      align-items: center;

      .lgn-title {
        font-size: 88px !important;
        color: #FFFFFF !important;
        padding-bottom: 30px !important;

        .dot {
          font-size: 88px !important;
          color: #FF080E !important;
        }
      }

      .sub {
        color: #FFFFFF !important;
        font-size: 39px !important;
        justify-content: center;
      }
    }
  }

  .right-side {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important;

    .form-container {

      .lgn-title {
        font-size: 64px !important;
        color: #1E1D1D !important;
      }

      .form {
        width: 100% !important;

        .showPasswordText {
          position: absolute;
        }

        .form-control {
          border-radius: 20px !important;
          border: 1px solid #707070;
          height: 80px !important;
          font-size: 32px !important;
          color: #3E3D3D !important;
          margin-bottom: 30px !important;
          padding-left: 20px;
          box-shadow: 0px 3px 6px #00000029;
        }

        .hasError {
          // border-radius: 20px !important;
          // height: 80px !important;
          // font-size: 32px !important;
          // color: #3E3D3D !important;
          // margin-bottom: 30px !important;
          // padding-left: 20px;
          // box-shadow: 0px 3px 6px #00000029;
          border: 1px solid red !important;
        }

        .padding-right-72px {
          padding-right: 72px;
        }

        .eye-position {
          position: absolute;
          top: 32px;
          right: 30px;
          font-size: 20px;
          z-index: 9;
        }

        .forgot-password {
          font-size: 28px !important;
          cursor: pointer !important;
          align-items:center;
          }
        
        .forgot-password-holder {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 50px;
        }
        
        .forgot-password:hover {
         color: #0068FF !important;
         transform: scale(1.1) !important;
        }

        .submit-btn {
          width: 420px !important;
          border-radius: 39px !important;
          height: 77px !important;
          font-size: 30px !important;
        }
      }

      .socmed-icon {
        cursor: pointer !important;
        margin-right: 30px !important;
        border-width: 1px solid !important;
      }
    }
  }
}

.side-nav-toggle:hover {
  color: #1C71DE !important;
}

.assessment-page {
  color: #3E3D3D !important;
  height: 100vh !important;
  font-size: 18px !important;
  margin: auto !important;


  .cosmos-title {
    color: #FFFFFF !important;
    font-weight: bold !important;
    margin-top: 50px !important;
    margin-bottom: 250px;
  }

  .dot {
    color: #FF080E !important;
  }

  .side-title {
    background-color: #1C71DE !important;
    height: 100vh !important;
    text-align: center !important;
    display: grid;
    justify-items: center;
    align-content: space-between;
  }

  .side-image {
    width: 300px !important;
    margin-bottom: 50px !important;
  }

  .assessment-container {
    margin: 50px !important;
    color: #3E3D3D !important;
  }

  .assessment-header {
    color: #3E3D3D !important;
    font-weight: bold !important;
    margin: auto !important;
    margin-bottom: 10px !important;
  }

  .instructions {
    color: #3E3D3D !important;
    font-size: 20px !important;
  }

  .assessment-form {
    border-radius: 49px !important;
    border-width: 0ch !important;
    background-color: #F3F6FF !important;
    padding: 10px !important;
    width: 90% !important;
    margin-bottom: 10px !important;
    padding-left: 20px !important;
  }

  .assessment-questions {
    font-weight: bold !important;
    color: #3E3D3D !important;
    font-size: 18px !important;
  }

  .btn-holder {
    display: flex !important;
    justify-content: center !important;

  }

  .assessment-btn {
    width: 300px !important;
    border-radius: 40px !important;
    font-weight: bold;
  }

  .radio-btn-group {
    display: flex !important;
    flex-direction: column !important;
  }

}

.assessment-landing {
  background-color: #F3F6FF !important;
  color: #3E3D3D !important;
  height: 100vh !important;
  display: block !important;

  .upper-container {
    margin: auto !important;
    padding: 20px;
    // margin-bottom: 60px !important;
  }

  .alp-title {
    color: #3E3D3D !important;
    font-weight: bold !important;
    font-size: 40px !important;
    margin-bottom: 20px !important;
  }

  .dot {
    color: #FF080E !important;
  }

  .img-landing {
    width: 320px !important;
  }

  .user-name {
    font-weight: bold !important;
    color: #4A4747 !important;
  }

  .recommendation {
    color: #4A4747 !important;
    // margin-top: 40px !important;
    // padding: 10px !important;
    font-size: 24px !important;
    // padding-bottom: 120px !important;
  }

  .title-wrap {
    margin: 50px !important;
    display: flex !important;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }

  .reco-wrap {
    margin-top: 180px !important;
  }

  .top-container {
    margin-bottom: 10px !important;
  }

  .bottom-container {
    // margin: auto !important;
    padding-top: 30px !important;
    background-color: #F3F6FF !important;
  }

  .card-style {
    width: 25rem !important;
    border-radius: 5px !important;
    border-width: 1px !important;
    border-color: #F3F3F3 !important;
    opacity: 1px !important;
    margin: auto !important;
    display: flex !important;
    margin-bottom: 20px !important;
    cursor: pointer !important;
  }

  .btn-instructions {
    width: 220px !important;
    border-radius: 39px !important;
    padding: 5px !important;
  }

  .card-text1 {
    font-weight: bold !important;
    color: #3E3D3D !important;
  }

  .card-subtext {
    color: #4A4747 !important;
    font-size: 12px !important;
    margin-top: 10px !important;
  }

  .card-title1 {
    color: #2CC4B6 !important;
    margin-top: 10px !important;
  }

  .card-title2 {
    color: #1A62C3 !important;
    margin-top: 10px !important;
  }

  .card-title3 {
    color: #A501FF !important;
    margin-top: 10px !important;
  }
}

.goal-container {
  padding: 30px !important;
  display: flex !important;
  flex-direction: column !important;

  .img-holder {
    display: flex !important;
    justify-content: center !important;
  }

  .trophy-img {
    width: 140px !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 10px !important;
  }

  .percent-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .percent {
    display: flex !important;
    justify-content: space-between !important;
  }

  .prog-bar {
    margin-top: -10px;
    border-radius: 4px !important;
    opacity: 1 !important;
    height: 7px !important;
  }
}

.user-stars {
  height: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;

  .container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-bottom: 2px !important;

    .img {
      width: 40px !important;
    }

    .name {
      font-size: 14px !important;
      font-weight: bold !important;
    }

    .star-holder {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      font-size: 10px !important;
      color: #707070 !important;
    }

    .star {
      width: 12px !important;
    }
  }
}

.learning {
  .my-2 {
    margin-left: -13px !important;
  }

  .learnings {
    display: grid !important;

    .img {
      width: 60px !important;
      height: 60px !important;
      display: flex !important;
    }

    .img-holder {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
    }

    .target-holder {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding-left: 5px !important;
      padding-top: 10px !important;
    }

    .prog-holder {
      display: flex !important;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }

    .holder {
      border-radius: 15px !important;
      height: 80px !important;
      overflow: hidden !important;
      background-color: white !important;
      border-radius: 15px !important;
      display: flex !important;
      margin-right: 10px !important;
      margin-bottom: 10px !important;
      margin-left: -15px !important;
    }
  }
}

.topics-holder {
  display: flex !important;
  padding: 20px !important;

  .col-holder {
    display: flex !important;
    align-content: center !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .img {
    width: 50px !important;
  }

  .count {
    color: #1E1D1D !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 5px !important;
  }

  .label {
    color: #707070 !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
}

.monitoring-title {
  h1 {
    font-size: 40px;
    margin: 0px 0px 8px;
    margin-left: -12px;
  }

  p {
    font-size: 20px;

    margin-left: -10px;
  }
}

.monitoring {
  .learnings {
    h4 {
      margin-left: -13px;
    }

    .learning-container {
      display: inline-flex;
      overflow-y: scroll !important;
      margin-left: -11px;
      margin-top: -5px;
    }

    .img {
      width: 60px !important;
      height: 60px !important;
      display: flex !important;
    }

    .img-holder {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
    }

    .target-holder {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px !important;
      padding-top: 10px !important;
    }

    .prog-holder {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .holder {
      border-radius: 15px !important;
      width: 320px !important;
      height: 80px !important;
      overflow: hidden !important;
      background-color: white !important;
      border-radius: 15px !important;
      display: flex !important;
      margin-right: 15px !important;
      width: 25rem !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .course-progress {
    h4 {
      margin-left: -13px;
    }

    .card-container {
      display: inline-flex;
      overflow-y: scroll !important;
      margin-left: -13px;
    }

    .cards {
      margin-bottom: 10px !important;
      border-radius: 5px !important;
      border-width: 0px !important;
      margin-right: 15px !important;
      width: 20rem !important;

      .marketing-title {
        font-size: 16px !important;
      }

      .uiux-title {
        color: #1A62C3 !important;
        font-size: 16px !important;
      }

      .analytics-title {
        color: #A501FF !important;
        font-size: 16px !important;
      }

      .subtitle {
        font-size: 16px !important;
        color: #3E3D3D !important;
      }

      .progressbar {
        margin-top: 10px !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        font-size: 10px !important;
      }

      .progbar {
        width: 120px !important;
        height: 7px !important;
      }
    }
  }

  .suggested-course {
    h4 {
      margin-left: -13px;
    }

    .card-container {
      display: inline-flex;
      overflow-y: scroll !important;
      margin-left: -13px;
    }

    .cards {
      margin-bottom: 10px !important;
      border-radius: 22px !important;
      border-width: 0px !important;
      margin-right: 15px !important;
      width: 14rem !important;
      box-shadow: 0px 3px 6px #00000029;

      .title {
        font-size: 16px !important;
        margin-top: 10px !important;
      }

      .subtitle {
        font-size: 12px !important;
        color: #7D7D7D !important;
      }
    }
  }
}

.certificate {
  h1 {
    font-size: 40px;
    margin-left: -13px;
  }

  p {
    font-size: 20px;
    margin: 0px 0px 16px;
    margin-left: -12px;
  }

  .avatar-row {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;

    .name {
      font-weight: bold !important;
      font-size: 16px !important;
      display: flex !important;
      justify-content: center !important;
      margin-right: 10px !important;
      flex-direction: column !important;
      align-items: flex-end !important;
      width: 177.57px;
      height: 48px;
      margin: 0px 10px 0px 0px;
    }

    .email {
      // font-size: 14px !important;
      font-weight: normal !important;

    }

    .avatar-container {
      padding: 20px;
      display: flex;
      justify-content: flex-end;

      img {
        height: 65px !important;
        width: 65px !important;
        border-radius: 50px !important;
        border-width: 2px !important;
        border-style: solid !important;
        border-color: #707070 !important;
      }
    }

  }

  .course-completed {
    h4 {
      margin-left: -13px;
    }

    .card-container {
      display: flex;
      margin-left: 0px !important;
      overflow-y: scroll;
      padding-left: 0px !important;
      align-items: center !important;
      height: 340px !important;
      margin-right: 15px;
    }
    .card-container-admin {
      display: flex;
      column-gap: 50px;
      row-gap: 50px;
      flex-wrap: wrap;
      
    }

    .cards-admin {
      border-radius: 22px !important;
      border-width: 0px !important;
      width: 22rem !important;
      height: 19rem !important;
      box-shadow: 0px 3px 6px #00000029;

      .float-right {
        float: right;
      }

      .marketing-title {
        font-size: 16px !important;
      }

      .subtitle {
        font-size: 14px !important;
        color: #707070;
        cursor: default;
      }

      .complete-img {
        width: 63px;
        height: 53px;
      }

      .progressbar {
        margin-top: 10px !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        font-size: 10px !important;
      }

      .progbar {
        width: 120px !important;
        height: 7px !important;
      }

      .button-holder {
        display: flex !important;
        justify-content: space-between !important;
      }

      .button {
        border-radius: 11px !important;
        width: 109px !important;
        height: 20px !important;
        font-size: 10px !important;
        font-weight: bold;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
      .img-card {
        border-radius: 11px !important;
        height: 140px !important;
        margin-top: 10px;
      }
    }

    .cards {
      border-radius: 22px !important;
      border-width: 0px !important;
      width: 22rem !important;
      height: 19rem !important;
      box-shadow: 0px 3px 6px #00000029;
      margin-right: 50px;
      margin-left: 10px;
      display: flex;

      .float-right {
        float: right;
      }

      .marketing-title {
        font-size: 16px !important;
        font-weight: bold !important;
      }

      .uiux-title {
        color: #1A62C3 !important;
        font-size: 16px !important;
        font-weight: bold !important;
      }

      .analytics-title {
        color: #A501FF !important;
        font-size: 16px !important;
        font-weight: bold !important;
      }

      .subtitle {
        font-size: 14px !important;
        color: #707070;
        cursor: default;
      }

      .complete-img {
        width: 63px;
        height: 53px;
      }

      .progressbar {
        margin-top: 10px !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        font-size: 10px !important;
      }

      .progbar {
        width: 120px !important;
        height: 7px !important;
      }

      .button-holder {
        display: flex !important;
        justify-content: space-between !important;
      }

      .button {
        border-radius: 11px !important;
        width: 109px !important;
        height: 20px !important;
        font-size: 10px !important;
        font-weight: bold;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
      .img-card {
        border-radius: 11px !important;
        height: 140px !important;
        margin-top: 10px;
      }
    }

  }

  .table-container {
    margin-left: -1px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    border-radius: 22px !important;
    background-color: white !important;
    overflow-y: scroll !important;
    height: 340px !important;
    font-weight: bold !important;

    .align-text {
      margin-top: 10px !important;
    }

    .trainor-btn {
      width: 109px !important;
      height: 20px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 10px !important;
      font-weight: bold;
      border-radius: 11px !important;
    }

    .img {
      width: 63px !important;
      height: 63px !important;
    }
  }
}

.reports {
  // .table-container {
  //   margin-left: -1px !important;
  //   padding-left: 30px !important;
  //   padding-right: 30px !important;
  //   border-radius: 22px !important;
  //   background-color: white !important;
  //   overflow-y: scroll !important;
  //   height: 340px !important;
  //   font-weight: bold !important;
  // }
  .icon3 {
    width: 50px;
    height: 50px;
    margin-bottom: 5%;
  }
  .table {
    background-color: white !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: none;
    border-radius: 22px 22px 0px 0px !important;
    overflow-y: scroll !important;
    .align-text {
      margin-top: 10px !important;
    }
  
    .upload-btn {
      width: 156px !important;
      height: 25px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 12px !important;
      font-weight: bold !important;
      border-radius: 11px !important;
    }

    .delete-btn {
      width: 156px !important;
      height: 25px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 12px !important;
      font-weight: bold !important;
      border-radius: 11px !important;
      background-color: rgb(225, 87, 72) !important;
      border: none ;
    }

    .retake-btn{
      width: 70px !important;
      height: 25px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 12px !important;
      font-weight: bold;
      border-radius: 11px !important;
      background-color: rgb(225, 87, 72) !important;
      border: none ;
      color: #fff !important;
    }

    .retake-btn-holder {
      width: 5%;
    }

    .upload-btn-holder {
      width: 10% !important;
    }

    .icon3 {
      width: 50px;
      height: 50px;
      margin-bottom: 5%;
    }
  }
  
}

.cttitle {
  margin-left: -13px;
}



.inline-flex {
  display: inline-flex;
}

.progressbar {
  padding: 3px;
  font-size: 10px;
  text-align: center;
}

.task-img {
  float: left;
  padding-right: 15px;

}

.dashboard-task-content-card-title {
  font-size: 12px !important;
}

.dashboard-task-content-card-text {
  font-size: 11px !important;
}

.text-color {
  color: #0068FF;
}

.btn-add-course {
  font-size: 14px !important;
  padding: 5px !important;
  background-color: transparent !important;
  border-style: solid !important;
  color: #0d6efd !important;
  height: 36px !important;
}
.btn-primary:hover {
  background-color: #1C71DE !important;
  color: white !important;
  border-color: #1C71DE !important;
}

.plus {
  padding-left: 0px;
  padding-right: 4px;
}

.inline-flex {
  display: inline-flex;
}

.rsc-header {
  float: right !important;
  display: inline-flex;

  .avatar-row {
    .name {
      justify-content: end;
      align-items: center;
      display: flex;

      p {
        margin-bottom: 0;
        text-align: right;
      }
    }
  }

}

.avatar-container {
  padding: 20px;
  display: inline-flex !important;
  justify-content: end;
  align-items: center;

  p {
    margin-bottom: 0;
    text-align: right;
    margin-right: 10px;
  }

  img {
    height: 65px !important;
    width: 65px !important;
    border-radius: 50px !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: #707070 !important;
    float: left !important;
    margin-bottom: 6px;
    margin-top: -12px;
  }
}

.resources-header {
  h1 {
    font-size: 40px;
  }

  p {
    font-size: 20px;
    margin-left: 2px;
  }
}

.resources {
  h5 {
    margin-top: 20px;
    padding: 0px 12px;
    margin-left: -20px;
  }

  .delete-holder {
    cursor: pointer;
  }

  .upload-holder {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;

    .upload-file-btn {
      height: 30px !important;
      width: 120px !important;
      font-size: 13px !important;
      display: flex !important;
      justify-content: center;
      align-items: center !important;
      gap: 0.5rem;
      font-weight: bold !important;
      border-radius: 20px !important;
    }
  }

  .fixTableHead {
    overflow-y: auto;
    display: flex !important;
    background-color: #FFFFFF;
    border-radius: 22px;
  }

  .fixTableHead thead th {
    position: sticky;
    top: 0;
    padding-bottom: 10px !important;
  }

 

  table {
    border-collapse: collapse !important;
    width: 100%;
    margin-left: 30px !important;
    margin-right: 30px !important;
    
  }

  thead,
  th {
    background: white !important;
    height: 50px;
    
  }

  td {
    font-weight: bold !important;
  }

  // .table-container {
  //   margin-left: 10px !important;
  //   padding-left: 30px !important;
  //   padding-right: 30px !important;
  //   border-radius: 22px !important;
  //   background-color: white !important;
  //   overflow-y: scroll !important;
  //   height: 340px !important;
  //   font-weight: bold !important;
  //   .img {
  //     width: 63px !important;
  //     height: 63px !important;
  //   }
  // }
}

.resources-cards {
  .card-holder {
    display: flex !important;
    column-gap: 50px;
    row-gap: 50px;
    flex-wrap: wrap;
    margin-bottom: 20px !important;
  }

  .card {
    height: 15rem;
    width: 22rem;
    border-radius: 22px !important;
    border-width: 0px;
    box-shadow: 0px 3px 6px #00000029;

    .course-card-title {
      font-size: 16px !important;
    }

    .course-card-text {
      font-size: 14px !important;
      font-weight: bold;
      cursor: default;
    }
    .sub-text {
      color: #000 !important;
    }
    .img-card {
      height: 142px !important;
      border-radius: 11px !important;
      
    }
  }
}

.quick-access {
  h5 {
    margin-top: 20px;
    margin-left: 3px;
  }

  .card {
    border-radius: 20px 20px 20px 20px !important;
    margin: 5px;
    margin-left: -12px;
    height: 80px;
  }

  .card-title {
    font-size: 12px !important;
    margin-top: 2%;
  }

  .card-text {
    font-size: 12px !important;
  }

  .img {
    float: left;
    padding-right: 15px;
    height: 40px;
    padding-top: 5.5%;
  }

  .float-right {
    float: right;
  }

  .quick-access-container {
    display: inline-flex;
    overflow-y: scroll !important;
    margin-left: 15px;
    margin-right: 13px;
  }


  .qcontainer1,
  .qcontainer2,
  .qcontainer3,
  .qcontainer4,
  .qcontainer5,
  .qcontainer6 {
    width: 250px;
    height: 109px;
  }

  .qcontainer2,
  .qcontainer3,
  .qcontainer4,
  .qcontainer5,
  .qcontainer6 {
    padding-left: 20px;
  }
}


.folder-resourses {
  h5 {
    margin-top: 0px;
    margin-left: 3px;
  }

  .card {
    border-radius: 20px 20px 20px 20px !important;
    margin: 5px;
    margin-left: -12px;
  }

  .card-title {
    font-size: 12px !important;
  }

  .card-text {
    font-size: 12px !important;
  }

  .float-right {
    float: right;
  }

  .folder-container {
    display: inline-flex;
    overflow-y: scroll !important;
    padding-bottom: 5px;
    margin-left: 15px;
    margin-right: 13px;
  }

  .fcontainer1 {
    width: 183px;

  }

  .fcontainer2,
  .fcontainer3,
  .fcontainer4,
  .fcontainer5,
  .fcontainer6,
  .fcontainer7,
  .fcontainer8,
  .fcontainer9,
  .fcontainer10 {
    width: 203px;
  }

  .fcontainer2,
  .fcontainer3,
  .fcontainer4,
  .fcontainer5,
  .fcontainer6,
  .fcontainer7,
  .fcontainer8,
  .fcontainer9,
  .fcontainer10 {
    padding-left: 20px;
  }
}

.sweet-alert-btn1 {
  width: 30%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E5E4E2;
  border-style: none;
  border-radius: 5px;
  padding: 1rem;
  color: #000;
  font-size: 20px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px inset, rgba(229, 228, 226, 0.6) 0px 0px 8px;
}
.sweet-alert-btn1:hover {
  background-color: #cccbc8;
}

.sweet-alert-btn2 {
  width: 30%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DC3545;
  border-style: none;
  border-radius: 5px;
  padding: 1rem;
  color: #fff;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px inset, rgba(220, 53, 69, 0.6) 0px 0px 8px;
}

.sweet-alert-btn2:hover {
  background-color: #a52633;
}

.sweet-alert-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5%;
}

.icon3 {
  width: 50px;
  height: 50px;
  margin-bottom: 5%;
}
.dropdown-btn{
}

.dropdown-btn .btn-primary {
  color:#707070 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  width: 10px !important;
  position: absolute;
  left: -20px;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  box-shadow: 0 0 0 0 !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: white !important;
  background-color: #1C71DE !important;
}
.dropdown-icon {
  background-color: pink !important;
}


.course-tab {
  .card {
    border-radius: 22px;
    width: 22rem !important;
    height: 14.5rem !important;
    box-shadow: 0px 3px 6px #00000029;
  }
  

  .col-margin-bottom {
    display: flex;
    column-gap: 50px !important;
    flex-wrap: wrap;
    row-gap: 50px;
    margin-bottom: 20px;
  }
  .card-img {
    height:135px !important;
    border-radius: 11px;
  }
  .card-img-teacher {
    height:120px !important;
    border-radius: 11px;
  }

  .enroll-btn-holder {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    margin-top: 5px !important;
    .enroll-btn {
      width: 30% !important;
      height: 20px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 10px !important;
      font-weight: bold !important;
      border-radius: 11px !important;
    }
  }
  
  
  .course-card-title {
    font-size: 16px !important;
    cursor: pointer !important;
  }

  .course-card-text {
    font-size: 14px !important;
    color: #3E3D3D;
    cursor:default;
  }

  .btn-holder-elipsis{
    display: flex;
    justify-content: flex-end;
  }

  .progressbar {
    margin-top: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-size: 10px !important;
  }

  .progbar {
    width: 120px !important;
    height: 7px !important;
    margin-left: 1px;
  }

  .card-img-top {
    height: 95px !important;
  }

  .btn-primary {
    height: 25px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
  }

  .btn-light {
    color: #fff;
    background-color: #2CC4B6;
  }

  .btn-light:hover {
    color: #fff;
    background-color: #2bb3a6;
  }

  .icon3 {
    width: 50px;
    height: 50px;
    margin-bottom: 5%;
  }
}

.course-activity {
  .row-container {
    background-color: white !important;
    padding: 50px 100px 50px 100px !important;
    margin-top: 50px !important;
  }

  .col-answer-container {
    background-color: #F6F7F8 !important;
    padding: 10px !important;
  }
}

.course-vid {
  h5 {
    color: #1E1D1D;
  }

  .table-title{
    
  }

  .btn-holder{
    display: flex !important;
    justify-content: flex-end !important;
    gap: 20px !important;
  }

  .video-icon {
    display: flex !important;
    align-items: center !important;
    color: white !important;
    margin-right: 5px;
  }

  .table-body-video {
    display: flex !important;
  }

  .digital-title {
    font-size: 20px !important;
  }

  // .video-btn {
  //   border-width: 0px !important;
  //   background-color: #0a58ca !important;
  // }

  .video-delete-btn {
    border-radius: 11px;
    height: 25px;
    display: flex;
    align-content: center;
    .delete-btn {
      display: flex !important;
      align-self: center !important;
      font-size: 11px !important;
      color: white !important;
    }
  }

  .video-back-btn {
    height: 40px !important;
    border-width: 0px !important;
    background-color: transparent !important;
    color: #0068FF;
  }
  .video-back-btn:hover{
    height: 40px !important;
    border-width: 0px !important;
    background-color: transparent !important;
    color: #0068FF;
    scale: 1.2 !important;
  }
}

.course-content {
  h5 {
    color: black;
  }

  .float-right {
    float: right;
  }

  .margin-25 {
    margin: 25px;
  }

  .input-content {
    height: 350px !important;
    background-color: #F6F7F8 !important;
    padding-top: 10px !important;
    padding-bottom: 10px;

  }

  .instructions {
    background-color: white !important;
    padding: 25px 50px 25px 50px !important;
    margin-bottom: 25px;
  }
}

.course-taken-card {
  // background-color: #f3f6ff;
  // min-height: 100vh;
  .holder {
    display: flex;
    justify-content: center;
    row-gap: 50px;
    column-gap: 50px;
    flex-wrap: wrap;
  }

  .lesson-btn-holder {
    margin-top: 0.3rem;
    display: flex;
    justify-content: space-between;
  }

  .lesson-btn {
    border-radius: 11px !important;
    width: 109px !important;
    height: 20px !important;
    font-size: 10px !important;
    font-weight: bold;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .col-holder {
    display: flex !important;
  }

  .button-holder {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .card {
    width: 22rem;
    height: 15rem;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    border-radius: 22px;
    margin-top: 20px;
    border-width: 0px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .margin-bottom {
    margin-bottom: 10px !important;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .font-size-10 {
    font-size: 12px;
    letter-spacing: 0px;
  }


  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: -7px;
    position: relative;
  }

  .module-name {
    cursor: pointer;
    .module-title {
      font-size: 16px !important;
      font-weight: bolder;
    }
    .module-sub{
      font-size: 10px !important;
    }
  }

  .icon-image {
    height: 50%;
  }

  .btn-light {
    color: #fff;
    background-color: #2CC4B6;
  }

  .btn-light:hover {
    color: #fff;
    background-color: #2bb3a6;
  }

  .bread-nav {
    display: inline-flex !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #3E3D3D;
  }

  .bread-nav:hover {
    color: #1C71DE !important;
    // text-decoration: underline;
  }

  .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: none;
  }


  .content-holder{
    height: 79vh !important;
    overflow: scroll !important;
    background-color: white;
    padding: 10px 10px;
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;
    overflow-y: auto;
  }

  .questions-holder {
    padding: 0px 10px !important;
    display: flex;
    margin-top: 10px;
    .exam-part-dropdown {
      margin: 10px 0px;
      display: flex !important;
      .module-exam-title{
        display: flex;
        align-items: center;
        width: 90%;
      }
      .module-exam-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 10%;
        gap: 20px;
        color: #0d6efd;
      } 
    }
  }
  

  .question-content {
    padding: 0px 20px;
  }

  .module-exam-part {
    border: 1px solid #2F88CA;
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
    display: flex;
    
    flex-direction: column;
  }

  .title-content {
    // color: #000;
    overflow-wrap: break-word;
    font-size: 1.5em;
    font-weight: bold;
    margin: 15px 15px;
  }

  .video-player{
    display: flex;
    justify-content: center;
    margin: 0px 50px;
  }

  .text-content{
    display: block;
    margin: 0px 15px;
  }

  // .lessons-accordion-holder {
  //   background-color: white;
  //   padding: 5px 5px;
  //   border-radius: 2px;
  //   box-shadow: 0px 3px 6px #00000029;
  //   max-height: 80vh;
  //   border-style: solid 1px;
  //   overflow-x: scroll;
  //   background-color: pink;
  //   width: min(500px, 1000px);
  // }

  .dropdown-btn-holder {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 14px;
  }

  .dropdown-btn-lesson{
    position: static;
  }
  
  .dropdown-btn-lesson .btn-primary {
    color:#707070 !important;
    background-color: transparent !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent !important;
    // width: 10px !important;
    // position: absolute;
    // left: 80px;
    // top: -20px;
  }

  .nav-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .btn-nav-lesson {
    color:#3E3D3D !important;
    background-color: transparent !important;
    border-color: transparent !important;
    row-gap: 10px !important;
    font-size: 14px !important;
    display: inline-flex;
    height: 20px;
    align-items: center;
    gap: 5px;
  }

  .btn-nav-lesson:hover{
    color:#1C71DE !important;
  }

}

.lesson-container {
  background-color: white;
  padding: 5px 5px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 80vh;
  overflow-x: scroll;
  padding: 2px 2px;
  margin-bottom: 10px;
  .lesson-collapse {
    margin: auto;
    border-width: 0px 0px 1px 0px !important;
    border-style: solid ;
    border-color: #5d4a4a29;
    cursor: pointer;
  }
  .lessons-holder {
    display: flex;
    justify-content: space-between;
  }
  .lesson-title {
    margin: 20px 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .lesson-edit {
    align-self: center;
  }

  .lesson-collapse:hover {
    background-color: #cce0fa;
    border-width: 2px;
  }
  .lesson-icon{
    display: flex;
    justify-content: end;
    gap: 20px;
  }
  .content-title {
    display: flex;
    align-items: center;
    gap: .6rem;
    flex-direction: row;
    font-size: 12px;
    padding: 10px 15px;
    cursor: pointer;
    min-height: 30px;
    overflow-y: none !important;
    word-break:normal ;
    border: solid 1px #9da3a73f;
    border-width: 0px 0px 1px 0px;
  }
  .content-title:hover {
    background-color: #cce0fa;
    border-color: #0068FF;
    border: solid 1px #9da3a73f;
    border-width: 0px 0px 1px 0px;
  }
  .icon1:hover {
    transform: scale(1.3);
  }
  .content-title-name {
    width: 70%;
    white-space: wrap;
  }
}

.course-header {
  h1 {
    font-size: 40px;
    margin-left: -2px;
  }

  .course-header-title {
    display: flex;
    gap: 20px;
  }

  p {
    font-size: 20px;
  }
  .add-course-btn{
    display: flex !important;
    justify-content: flex-end !important;
  }
  .search-course {

    .font-size {
      font-size: 18px !important;
      display: inline-flex !important;
      margin-top: 6px;
    }
  
    .searchbar {
      box-shadow: 0px 3px 5px #00000029;
      border-radius: 5px;
      max-width: 400px;
      // border-style: solid;
      // border-width: 1px;
    }
  
    .search-icon {
      border-radius: 11px 0px 0px 11px;
      border-style: none;
    }
  
    .searchtab {
      border-radius: 0px 11px 11px 0px !important;
      border-style: none;
    }
  
    .input-group:focus-within {
      color: #212529;
      background-color: #fff;
      // border-color: #0068FF;
      box-shadow: 0px 3px 5px #00000029;
      border-radius: 5px;
      // border-style: solid;
      border-width: 1px;
    }
  
    .input-group .form-control:focus {
      box-shadow: none !important;
    }
  
    #basic-addon1 {
      background-color: #FFFFFF;
    }
  
    .icon {
      width: 20px;
      height: 20px;
    }
    
  }
}

.calendar-header {
  h1 {
    font-size: 40px;
  }

  p {
    font-size: 20px;
  }

}

.calendar-page {
  h5 {
    margin-bottom: 20px;
    margin-top: 17px;
  }

  .calendar-container {
    background-color: white;
    padding: 25px;
    height: 440px;
    overflow-y: scroll !important;
    border-radius: 22px;
    margin-left: 1%;
    padding-left: 2%;
    margin-right: 10px;
  }

  .rbc-toolbar button {
    border-radius: 28px;
  }

  .rbc-toolbar button.rbc-active,
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus,
  .rbc-toolbar button:focus {
    background-color: #1C71DE;
    color: #fff;
    border-color: #8c8c8c;
    transition: 0.3s all;
  }

}

.video-icon-content {
  color: #1C71DE;
  font-size: 12px;
}

.ongoing-lesson {
  .card-container {
    display: inline-flex;
    overflow-y: scroll !important;
    margin-left: 10px;
    margin-right: 13px;
  }

  .card-margin-right-15 {
    margin-right: 15px;
    width: 17rem;
    border-radius: 22px !important;
    // margin: 5px !important;
    margin-left: -10px;
    margin-top: 10px;
  }

  .card-title {
    font-size: 13px !important;
    color: #2CC4B6;
  }

  .card-text {
    font-size: 10px !important;
    color: #3E3D3D;
  }

  .progressbar {
    margin-top: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-size: 10px !important;
  }

  .progbar {
    width: 120px !important;
    height: 7px !important;
  }
}

.dashboard {
  .inline-flex {
    display: flex;
  }

  .font-size {
    font-size: 20px;
  }

  .inline-flex-subheader1 {
    display: inline-flex;
  }

  .inline-flex-subheader {
    display: inline-flex;
    margin-left: 5px;
    margin-top: -10px;
  }

  .inline-flex-card {
    display: flex;
    margin-bottom: 9px;
  }

  .float-right {
    float: right;
  }

  .card-container-top {
    display: flex !important;
    flex-direction: column !important;
    overflow-y: scroll !important;
    margin-left: 15px;
    margin-right: -10px;
    margin-top: 10px;
    height: 300px !important;
    margin-bottom: 20px !important;
    justify-content: flex-start !important;
    column-gap: 50px !important;
  }

  .card-course {
    margin-top: 10px;
    width: 22rem !important;
    height: 17rem !important;
    border-radius: 22px !important;
    margin-bottom: 7px !important;
    padding: 0px !important;
  }

  .card-container {
    display: inline-flex;
    overflow-y: scroll !important;
    margin-left: 5px;
    margin-right: 13px;
    margin-top: -10px;
  }

  .card-bg {
    background-color: #D3DDFC !important;
    border-radius: 20px 20px 20px 20px !important;
  }

  .course-card {
    font-size: 13px !important;
    text-align: center;
  }

  .card-img{
    border-radius: 11px;
    height: 170px;
  }

  .space-around {
    display: flex;
    justify-content: space-around;
  }

  .card-content-upcoming {
    border-radius: 20px 20px 20px 20px !important;
    width: 32rem !important;
    margin: 5px;
  }

  .card-content-tasktoday {
    border-radius: 20px 20px 20px 20px !important;
    width: 32rem !important;
    margin-top: -10px;

  }

  .card-content-task {
    border-radius: 20px 20px 20px 20px !important;
    margin: 5px;
    width: 22rem !important;
  }

  .card-content-task-container {
    overflow-x: scroll !important;
    height: 280px;
    display: flex;
    flex-wrap: wrap;
  }

  .dashboard-content {
    height: 340px;
    margin-left: -25px !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    .inline-flex{
      margin-top: -21rem;
    }
  }

  .dashboard-content-card-title {
    font-size: 16px !important;
  }

  .dashboard-content-card-text {
    font-size: 14px !important;
    color: #3E3D3D;
  }

  .text-center {
    text-align: center !important;
    font-size: 10px;

  }

  .-top {
    padding-bottom: 5px;
  }

  .card-courseslike {
    margin: 18px;
    margin-left: -10px;
  }

  .card {
    width: 22rem;
    border-width: 0px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px !important;
    // margin-left: -10px !important;
    // margin: 5px !important;
    cursor: pointer;

    .course-card-title-marketing {
      font-size: 13px !important;
    }

    .course-card-text {
      font-size: 10px !important;
    }

    .course-card-title-design {
      font-size: 13px !important;
    }

    .course-card-title-analytics {
      font-size: 13px !important;
    }

    // .progressbar {
    //   margin-top: 10px !important;
    //   display: flex !important;
    //   justify-content: space-between !important;
    //   align-items: center !important;
    //   font-size: 10px !important;
    // }

    // .progbar {
    //   width: 120px !important;
    //   height: 7px !important;
    //   margin-left: 1px;
    // }
  }
}

.e-participants {
  .enroll-table {
    background-color: white !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: none;
    border-radius: 22px 22px 0px 0px !important;
    overflow-y: scroll !important;
    margin-top: 2rem;
  }

  .remove-btn {
    width: 85px !important;
      height: 25px !important;
      display: flex !important;
      flex-direction: row;
      align-items: center !important;
      justify-content: center !important;
      font-size: 12px !important;
      font-weight: bold;
      border-radius: 11px !important;
      border: none ;
      color: #fff !important;
  }

  .remove-btn-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

  .refresh-btn {
    background-color: #0d6efd;
    border: none;
    border-radius: 10%;
    padding: 10px;
    height: 40px;
    width: 50px;
    justify-content: center;
    margin-top: -89px;
    display: flex;
    align-items: center;
    margin-left: 23rem;
  }

  .column-1 {
    width: 20%;
    text-align: center;
  }

  .first-name {
    text-align: center;
  }

  .column-2 {
    width: 20%;
    text-align: center;
  }

  .middle-name {
    text-align: center;
  }

  .column-3 {
    width: 20%;
    text-align: center;
  }

  .last-name {
    text-align: center;
  }

  .column-4 {
    width: 40%;
    text-align: center;
  }

}



.w-participants{
  .enroll-table {
    background-color: white !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border: none;
    border-radius: 22px 22px 0px 0px !important;
    overflow-y: scroll !important;
    vertical-align: middle;
    margin-top: 2rem;
  }
    .accept-btn{
      width: 80px !important;
      height: 25px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 12px !important;
      font-weight: bold;
      border-radius: 11px !important;
      border: none ;
      color: #fff !important;
    }

    .reject-btn{
      width: 80px !important;
      height: 25px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 5px !important;
      font-size: 12px !important;
      font-weight: bold;
      border-radius: 11px !important;
      border: none ;
      color: #fff !important;
    }

    .refresh-btn{
      background-color: #0d6efd;
      border: none;
      border-radius: 10%;
      padding: 10px;
      height: 40px;
      width: 50px;
      justify-content: center;
      margin-top:-89px;
      display: flex;
      align-items: center;
      margin-left: 23rem;
    }
  .column-1{
    width: 20%;
    text-align: center;
  }
  .first-name{
    text-align: center;
  }
  .column-2{
    width: 20%;
    text-align: center;
  }
  .middle-name{
    text-align: center;
  }
  .column-3{
    width: 20%;
    text-align: center; 
  }
  .last-name{
    text-align: center;
  }
  .column-4{
    width: 40%;
    text-align: center;
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.dashboard1 {

  .h1,
  h1 {
    font-size: 40px;
    margin: 0px 0px 8px;
  }

  .p,
  p {
    font-size: 20px;
    margin: 0px 0px 16px;
    margin-left: 3px;
  }
}


.search {
  .font-size {
    font-size: 18px !important;
    display: inline-flex !important;
    margin-top: -6px;
  }

  .searchbar {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    min-width: 22vw;
    // border-style: solid;
    border-width: 1px;
    margin-top: -2px;
  }

  .search-icon {
    border-radius: 11px 0px 0px 11px;
    border-style: none;
  }

  .searchtab {
    border-radius: 0px 11px 11px 0px !important;
    border-style: none;
  }

  .input-group:focus-within {
    color: #212529;
    background-color: #fff;
    border-color: #0068FF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    // border-style: solid;
    border-width: 1px;
  }

  .input-group .form-control:focus {
    box-shadow: none !important;
  }

  #basic-addon1 {
    background-color: #FFFFFF;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
  
}



.pending {
  color: #C71F2A !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 5px !important;
}

.enrolled {
  color: #7BC878 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 5px !important;
}

.profile {
  .profile-top {
    height: 40vh;
    // background-image: url("../images/profilebg.svg");
    background-size: cover;
    background-color: #cccccc;
    background-repeat: no-repeat;
    display: flex !important;
    align-items: center;

    .arrow {
      margin-top: -200px !important;
      padding-left: 50px !important;

      .back-arrow {
        color: #FFFFFF !important;
        font-size: 25px !important;
        cursor: pointer !important;
      }

      .back-arrow :hover {
        scale: 1.2 !important;
      }
    }

    .avatar-container {
      .profile-image{
        background-color: transparent;
        height: 200px !important;
        width: 200px !important;
        border-radius: 120px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-style: solid !important;
        border-color: white !important;
      }

      .camera-icon {
        background-color: white;
        height: 50px !important;
        width: 50px !important;
        border-radius: 25px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        position: relative;
        right: 50px;
        top: 60px;
        .icon {
          font-size: 30px;
          color: rgb(24, 118, 184);
          cursor: pointer;
        }
      }

      .camera-icon :hover {
        scale: 1.1;
      }
    }

    .profile-name {
      color: #FFFFFF !important;
      font-size: 4vw !important;
      margin-left: 20px !important;
    }

    .profile-email {
      color: #FFFFFF !important;
      font-size: 2vw !important;
      margin-left: 20px !important;
    }
  }

  .form {
    width: 1080px !important;
    padding-left: 36px !important;
  }

  .btn-primary {
    width: 192px !important;
    height: 53px !important;
    font-size: 16px !important;
  }

  .form-control {
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px 10px !important;
    height: 65px;
  }

  .eye-position {
    right: 30px;
    top: 25px;
    position: absolute;
    z-index: 99;
  }

  .form-label {
    font-size: 16px !important;
  }

  .form-control {
    padding-left: 20px !important;
  }

  .change-pass-container{
    display: flex !important;
    justify-content: flex-end;
    margin-right: 20px;
  }


  .change-pass {
    font-size: 16px !important;
    color: #707070;;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    align-items:center;
    }


  .change-pass-holder{
    height:20px;
  }


  .change-pass:hover{
    background-color: transparent !important;
    color: #0068FF;
    transform:scale(1.1) !important;
  }
  
}

.exam {
  .exam-instructions {
    font-weight: bold !important;
    color: #1C71DE;
  }

  .exam-choices {
    display: flex !important;
    flex-direction: column !important;
  }

  .exam-submit-btn-holder {
    margin-top: 10px !important;
    text-align: end !important;
  }
}

.exam-modal-footer {
  display: flex !important;
  justify-content: space-between !important;
}

.modal-exam-header {
  display: flex;
  flex-direction: column !important;
  .instructions {
    color: #1C71DE !important;
    margin-right: 10px;
  }
}

.dropdown::after{
  display: none !important; 
}

.action-btn-1{
  .action-btn-2{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 40px !important
  }
}

.action-btn-3{
  .action-btn-4{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 35px !important;
    color: black !important;
  }
}

.icon1:hover {
  transform: scale(1.3);
}

.icon-delete {
  color: rgb(216, 71, 71);
}

.modal-button-part {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}