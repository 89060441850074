@import "constants";

.main-container {
  background-color: #F3F6FF;
  min-height: 100vh;
  .side-navigation {
    background-color: $white;
    transition: background-color 0.5s ease, margin-left 0.5s ease;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    font-weight: bold;
    // box-shadow: $drop-shadow;
    z-index: 999;
    display: flex;
    flex-direction: row;

    &.collapsed {
      margin-left: -250px;
    }
    .side-navigation-content {
      background-color: $white;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 48px;
      border-radius: 25px 25px 0px 0px;
      .navigation-links {
        flex: 1;
        .navigation-tab-container {
          box-shadow: 0px 6px 6px #00000015;
          .navigation-tab-indicator {
            background-color: $parent-line-color;
            height: 3px;
            width: 125px;
            margin-top: 10px;
            transition: margin-left 0.5s;
            &.active-ontraq {
              background-color: $ontraq-color;
              margin-left: 125px;
            }
          }
          .navigation-tab {
            display: flex;
            margin-top: 10px;
            padding-top: 10px;
            .navigation-tab-item {
              width: 125px;
              font-size: 16px;
              text-align: center;
              &.text-disabled {
                color: $light-gray !important;
              }
            }
          }
        }
        .navigation-links-container {
          margin-top: 30px;
          display: block;
          overflow-y: auto;
        }
      }

      .side-navigation-footer {
        .footer-logo {
          height: 80px;
          display: block;
          margin: 0 auto;
        }
      }
      .navigation-link-item,
      .logout-link {
        display: flex;
        padding: 10px 30px;
        margin: 0 10px;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 5px;

        &.parentline-link {
          &:hover,
          &.active {
            background-color: #1C71DE;
            span {
              color: $white;
            }
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
        &.ontraq-link {
          &:hover,
          &.active {
            background-color: $ontraq-color;
            span {
              color: $white;
            }
            img {
              filter: brightness(0) invert(1);
            }
          }
        }

        span {
          font-size: 16px;
        }
        img {
          margin-right: 38px;
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }

      .side-navigation-avatar {
        img {
          width: 75px;
          height: 75px;
          border-radius: 75px;
          object-fit: cover;
          display: block;
          margin: 0 auto;
          margin-top: -35px;
          background-color: rgb(233, 233, 233);
        }
        .side-navigation-avatar-initials {
          width: 75px;
          height: 75px;
          border-radius: 75px;
          font-size: 35px;
          font-weight: bold;
          color: $white;
          align-items: center;
          justify-content: center;
          display: block;
          display: flex;
          margin: 0 auto;
          margin-top: -35px;
          background-color: #0b8abc;
        }
        .name {
          letter-spacing: 0px;
          font-size: 15px;
        }
        .cosmos-sideNav{
          letter-spacing: 0px;
          font-size: 40px;
        }
        .position {
          letter-spacing: 0px;
          font-size: 12px;
        }
      }
    }
  }
  .content {
    margin-left: 250px;
    background-color: $white;
    flex: 1;
    position: relative;
    transition: margin-left 0.5s;
    &.hidden-sidenav {
      margin-left: 0;
    }
    .header {
      position: absolute;
      width: 100%;
      top: 0;
      height: 60px;
    }
    .header + * {
      margin: 0;
      padding-top: 60px;
    }
    .content-container {
      height: calc(100vh);
      overflow-y: auto;
      background-color: #F3F6FF;
      // border-top-left-radius: 100px;
      // border-bottom-left-radius: 100px;
    }
  }
}

.header-title {
  display: flex;
  align-items: center;
  height: 60px;
  flex: 1;
  padding: 0 12px;
  p {
    margin: 0 0 0 8px !important;
    font-size: 15px;
  }
}
.side-navigation-toggler {
  display: inline-block;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: $font-color;
  transition: transform 0.5s ease-in-out;
  -ms-transition: -ms-transform 0.5s ease-in-out;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  &.rotate {
    transform: rotate(180deg);
  }
}

.footer {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo-container {
    img {
      height: 40px;
    }
  }
}

.auth-container {
  padding-top: 45px;
  
  .title {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .login-container {
    max-width: 482px;
    margin: 0 auto;
    background-color: $white;
    border-radius: 10px;
    padding: 10px 44px;
    box-shadow: $drop-shadow;
    .logo {
      display: block;
      margin: 0 auto;
      &.parentline-logo {
        width: 100%;
      }
      &.ontraq-logo {
        width: 247px;
      }
    }
    form {
      max-width: 300px;
      display: block;
      margin: 0 auto;
    }
    .sign-in-link {
      margin-bottom: 128px;
    }
  }
}


